module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://mst.gracietco-vt-prod-lamp01.dcsrv.eu/wp/graphql","production":{"allow401Images":true,"allow404Images":true,"hardCacheMediaFiles":false},"type":{"MediaItem":{"localFile":{"maxFileSizeBytes":33554432,"excludeByMimeTypes":[],"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"22","matomoUrl":"https://stats-agence.graciet-co.fr/","siteUrl":"https://www.lavoituredepapi.fr/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"La Voiture de Papi","short_name":"La Voiture de Papi","start_url":"/","background_color":"#ece0cf","theme_color":"#5d421d","display":"standalone","icons":[{"src":"icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icon-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
